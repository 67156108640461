.active {
  border-bottom: solid #5a402e 2px;
  /* padding-bottom: 5px !important; */
  border-radius: 10px;
  transition: all 0.3s;
}

.heading {
  background-image: url(https://shubhambhanderi.github.io/ashima-asset.github.io/img/aboutus/aboutusbg.jpg);
  background-size: cover;
  background-position: bottom;
  color: white;
}

.nav-link {
  text-align: center;
  transition: all 0.3s;
}

#header {
  font-size: 1.3rem;
}
.icon {
  height: 3.5rem;
}

.navbar-toggler-icon {
  height: 1.5rem;
}

@media only screen and (max-width: 1100px) {
  #header {
    font-size: 1.3rem;
  }
  .icon {
    height: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .icon {
    height: 3rem;
  }
  .nav-link {
    padding: 8px !important;
  }
}

@media only screen and (max-width: 400px) {
  #header {
    font-size: 1.2em !important;
  }
  .icon {
    height: 2.7rem;
  }
  .navbar-toggler-icon {
    height: 1.2rem;
  }
}

@media only screen and (max-width: 300px) {
  .icon {
    height: 1.8rem;
  }

  #header {
    font-size: 0.9rem !important;
  }

  .navbar-toggler-icon {
    height: 1.2rem;
    width: 1rem;
  }
}
